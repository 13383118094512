import { Loading } from 'element-ui';

let loadingCount = 0;
let loading;
let numberCount = 0; //定时器增加的数值
let timer = null; //定时器
const loadingTime = 15; //超时时间值(秒)

const defaultState = {
  // text: "正在加载……",
  time: ""
}

const startLoading = (state) => {
  loading = Loading.service({
    lock: true,
    text: state.text,
    // text: '加载中……',
    background: 'rgba(0, 0, 0, 0.7)'
  });
  if(state.time){
    setTimeout(() => {
      endLoading();
      loadingCount = 0
    }, state.time);
  }

  // 超时关闭loading
  timer = setInterval(() => {
    numberCount++;
    if (numberCount > loadingTime) {
      endLoading();
      loadingCount = 0;
    }
  }, 1000);
};

const endLoading = () => {
  loading.close();
  // 接除timer
  clearInterval(timer);
  numberCount = 0;
};

export const showLoading = (state = defaultState) => {
  if (loadingCount === 0) {
    startLoading(state);
  }
  loadingCount += 1;
};

export const hideLoading = () => {
  if (loadingCount <= 0) {
    return;
  }
  loadingCount -= 1;
  // loadingCount = loadingCount-1;
  if (loadingCount === 0) {
    endLoading();
  }
};
