import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    isLogin: false,
    isRegister: false,
    isVerifi: false,
    student: {
      value: '',
      parentId: '',
      parentChild: '',
      all: '',
    },
    userInfo: {},
    verifiCode: '',
    IMSign: {},
    fusion: {
      from: null,
      path: '',
      token: ''
    },
    label: {
      medicalLabel: '医学学科',
      courseLabel: '课程标签'
    }
  },
  getters: {
    getUserInfo: (state) => state.userInfo,
    getIMSign: (state) => state.IMSign
  },
  mutations: {
    IMSign(state,sign) {
      state.IMSign = sign
    },
    USER_INFO(state, platform) { //用户信息
      state.userInfo = platform;
    },
    updateVerifiCode(state, code) {
      state.verifiCode = code
    },
    updateVerifi(state, verifi) {
      state.isVerifi = verifi
    },
    VALUE(state, paylaod) {
      state.student.value = paylaod
    },
    PARENTID(state, payload) {
      state.student.parentId = payload
    },
    PARENTChiLD(state, payload) {
      state.student.parentChild = payload
    },
    SETALL(state,payload) {
      state.student.all = payload
    },
    UPDATE_FUSION(state,payload) {
      state.fusion = payload
    },
    UPDATE_LABEL(state,payload) {
      state.label = payload
    }
  },
  actions: {},
  modules: {}
})
