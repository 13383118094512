import { Loading } from "element-ui"

let loadingInstance
export default {
  show (txt, isFullscreen) {
    let text = txt && typeof txt == "string" ? txt : "正在加载中..."
    let fullscreen = !isFullscreen && typeof !isFullscreen == "boolean" ? !isFullscreen : true
    const loadOption = {
      fullscreen,
      lock: true,
      text,
      spinner: "",
      background: "rgba(0, 0, 0, 0.7)",
    }
    loadingInstance = Loading.service(loadOption)
  },
  hide () {
    loadingInstance.close()
  },
}
