<template>
  <div id="app">
    <router-view name="students" :from="params.from" />
    <router-view name="admin" :from="params.from" />
    <router-view name="fusion" :from="params.from" />
    <span class="version">版本：V2.0.0.269826</span>
  </div>
</template>

<script>
import API from "@/apis/common";
import personalCenterAPI from "@/apis/studentsEndAPI/personalCenterAPI";
export default {
  name: "App",
  data() {
    return {
      params: {
        from: null,
        token: "",
        path: "",
      },
      flag: 0,
    };
  },
  watch: {
    "$route.query": {
      handler(val) {
        if (val && val.token) {
          this.params = val;
          this.getSkin();
        } else if (val.fusion) {
          if (JSON.parse(val.fusion).token) {
            this.params = JSON.parse(val.fusion);
            this.getSkin();
          }
        } else {
          this.params = this.$store.state.fusion;
        }
      },
      immediate: true,
    },
  },
  mounted() {
    // this.eventListener();
  },
  methods: {
    eventListener() {
      window.addEventListener(
        "message",
        (e) => {
          if (e.data.params != undefined) {
            this.skinFormat(e.data.params);
          }
        },
        false
      );
    },
    skinFormat(params) {
      sessionStorage.setItem("educationToken", params.token);
      this.init();
      this.$store.commit("UPDATE_FUSION", params);
      this.setLabel(params);
      this.$router.push("/" + params.path);
      document.head
        .querySelector("#skin")
        .setAttribute("href", `static/skin/${params.from}.css`);
    },
    getSkin() {
      sessionStorage.setItem("educationToken", this.params.token);
      this.$store.commit("UPDATE_FUSION", this.params);
      this.setLabel(this.params);
      this.init();
    },
    async init() {
      let res = await personalCenterAPI.getProfile(); //获取用户信息
      if (res) {
        this.$store.commit("USER_INFO", res);
        sessionStorage.setItem("profile", JSON.stringify(res));
      }
      let data = await API.getIMSign(); //获取腾讯imSign
      if (data) {
        sessionStorage.setItem("IMSign", JSON.stringify(data));
        this.$store.commit("IMSign", data);
      }
      if (this.params.from == "unifiedVideo") {
        this.$router.push("/");
        document.head
          .querySelector("#skin")
          .setAttribute("href", `static/skin/default.css`);
      } else {
        this.$router.push("/" + this.params.path);
        document.head
          .querySelector("#skin")
          .setAttribute("href", `static/skin/${this.params.from}.css`);
      }
    },
    setLabel(params) {
      if (params.from == "psychology") {
        let label = {
          medicalLabel: "分类",
          courseLabel: "标签",
        };
        this.$store.commit("UPDATE_LABEL", label);
      }
    },
  },
  destroyed() {
    window.addEventListener("message", null);
  },
};
</script>

<style lang="scss">
.el-loading-spinner {
  /*覆盖 element-ui  默认的 50%    因为此处设置了height:100%,所以不设置的话，会只显示一半，因为被top顶下去了*/
  top: 45% !important;
  width: 100%;
  height: 150px;
  /*这个是自己想设置的 gif 加载动图*/
  background-image: url("./assets/gif.gif");
  background-position: center;
  background-size: 280px 280px;
  background-repeat: no-repeat;
}
.el-loading-spinner .circular {
  /*隐藏 之前  element-ui  默认的 loading 动画*/
  display: none;
}
.el-loading-spinner .el-loading-text {
  /*为了使得文字在loading图下面*/
  margin: 160px 0 !important;
}
.version {
  position: fixed;
  bottom: 8px;
  left: 8px;
  z-index: 8;
  color: blue;
  font-size: 14px;
  opacity: 0;
  &:hover {
    opacity: 1;
  }
}
</style>
