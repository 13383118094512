import axios from "axios";
import { Message } from "element-ui";
import Vue from "vue";
import apiUrl from "../apis/baseUrl";
import router from "../router";
import { hideLoading, showLoading } from "./loading";
//环境配置
axios.defaults.baseURL = apiUrl.baseUrl;
Vue.prototype.baseURL = axios.defaults.baseURL;
//相关配置
axios.defaults.withCredentials = false; //解决跨域问题
axios.defaults.timeout = 10000; //设置请求超时
axios.defaults.headers.post["Content-Type"] = "text/plain;charset=UTF-8"; //post请求头的设置
//添加请求拦截器
axios.interceptors.request.use(
  (config) => {
    const isShow = config.isShow;
    if (config.url.startsWith("/token?")) {
      delete config.headers.Authorization;
    } else {
      // let token = window.access_token || window.top.access_token
      if (
        sessionStorage.getItem("educationToken") ||
        localStorage.getItem("educationToken")
      ) {
        config.headers.Authorization =
          sessionStorage.getItem("educationToken") ||
          localStorage.getItem("educationToken");
      } else {
        config.headers.Authorization = "";
      }
    }
    if (isShow || typeof isShow == "undefined") {
      showLoading();
    } else {
      hideLoading();
    }
    return config;
  },
  function (error) {
    hideLoading();
    return Promise.reject(error);
  }
);
//添加响应拦截器
axios.interceptors.response.use(
  (response) => {
    const headers = response && response.config && response.config.headers;
    const { needToast = true } = headers || {}; // 兼容接口
    hideLoading();
    // 处理文件返回
    const res = response.data;
    if (res.type === "application/octet-stream") {
      return response;
    }
    if (res.type === "application/vnd.ms-excel") {
      return response;
    }
    // 状态码200
    if (response.status === 200) {
      if (
        response.data.msg != "记录成功" &&
        response.config.url != "/client/course/zan"
      ) {
        needToast && response.data.msg && Message.success(response.data.msg);
        return Promise.resolve(response.data);
      }
    }
  },
  (err) => {
    hideLoading();
    if (err.response.data.msg != "用户已经提交过问卷") {
      switch (err.response.status) {
        case 400 || 401 || 403:
          // sessionStorage.clear();
          // localStorage.clear();
          router.replace({ path: "/" });
          // Message.error(err.response.data.msg);
          Message.error("请登录后再查看！");
          break;
        default:
          Message.error("请登录后再查看！");
      }
    }
    return Promise.resolve(err.response);
  }
);
export default {
  post(url, params, headers) {
    return axios.post(url, params, headers);
  },
  get(url, params) {
    return axios.get(url, params);
  },
  put(url, params) {
    return axios.put(url, params);
  },
  delete(url, params) {
    return axios.delete(url, { params });
  },
  axios, // 不要改，兼容接口需要用到
};
