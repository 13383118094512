import Vue from "vue";
import VueRouter from "vue-router";
import backStageEndExamRouter from "./backStageEndRouter/examManageRouter.js"; // 考试模块
import myCoursesRouter from "./backStageEndRouter/myCoursesRouter";
import studentsEndExamRouter from "./studentsEndRouter/exam.js"; // 考试模块
Vue.use(VueRouter);
const routes = [
  //前台路由
  {
    path: "/",
    name: "home",
    components: {
      students: () => import("@/views/studentsEnd/home"),
    },
    redirect: "/home",
    children: [
      ...studentsEndExamRouter, //考试
      {
        // 主页
        path: "/home",
        name: "home",
        component: () => import("@/views/studentsEnd/container"),
      },
      {
        //个人中心
        path: "/personalCenter",
        name: "personalCenter",
        component: () => import("@/views/studentsEnd/personalCenter"),
        redirect: "/collection",
        children: [
          {
            //收藏
            path: "/collection",
            name: "collection",
            component: () =>
              import("@/views/studentsEnd/personalCenter/collection"),
          },
          {
            //评价
            path: "/evaluate",
            name: "evaluate",
            component: () =>
              import("@/views/studentsEnd/personalCenter/evaluate"),
          },
          {
            //考试首页
            path: "/examination",
            name: "examination",
            component: () =>
              import("@/views/studentsEnd/personalCenter/examination"),
          },
          {
            //账户
            path: "/account",
            name: "account",
            component: () =>
              import("@/views/studentsEnd/personalCenter/account"),
          },
        ],
      },
      {
        // 直播页
        path: "/liveList",
        name: "liveList",
        component: () => import("@/views/studentsEnd/live/liveList"),
      },
      {
        // 点播页
        path: "/dibbleList",
        name: "dibbleList",
        component: () => import("@/views/studentsEnd/dibble/dibbleList"),
      },
      {
        // 讲师列表
        path: "/famousTeacher",
        name: "famousTeacher",
        component: () => import("@views/studentsEnd/famousTeacher/famousTeacher"),
      },
      {
        // 讲师详情
        path: "/teacherDetail",
        name: "teacherDetail",
        component: () => import("@views/studentsEnd/famousTeacher/components/teacherDetail"),
      },
      {
        // 咨询页
        path: "/consultList",
        name: "consultList",
        component: () => import("@/views/studentsEnd/consult/consultList"),
      },

      {
        // 咨询详情页
        path: "/consultDetail",
        name: "consultDetail",
        component: () => import("@/views/studentsEnd/consult/consultDetail"),
      },
      {
        // 直播详情页
        path: "/liveDetail",
        name: "liveDetail",
        component: () => import("@/views/studentsEnd/live/liveDetail"),
      },
      {
        // 点播详情页
        path: "/dibbleDetail",
        name: "dibbleDetail",
        component: () => import("@/views/studentsEnd/dibble/dibbleDetail"),
      },
      {
        // 搜索页
        path: "/searchList",
        name: "searchList",
        component: () => import("@/views/studentsEnd/searchList"),
      },

    ],
  },
  //后台路由
  {
    path: "/admin",
    name: "admin",
    components: {
      admin: () => import("@/views/backStageEnd/home"),
    },
    children: [
      ...myCoursesRouter,
      ...backStageEndExamRouter,
      // 我的课件
      {
        // 视频库
        path: "/videoDatabase",
        name: "videoDatabase",
        component: () =>
          import("@/views/backStageEnd/myCourseware/videoDatabase"),
      },
      {
        // 文档库
        path: "/documentLibrary",
        name: "documentLibrary",
        component: () =>
          import("@/views/backStageEnd/myCourseware/documentLibrary"),
      },
      {
        // 讲师库
        path: "/lecturer",
        name: "lecturer",
        component: () =>
          import("@/views/backStageEnd/lecturer"),
      },
      //我的审核
      {
        // 课程审核
        path: "/courseCheck",
        name: "courseCheck",
        component: () => import("@/views/backStageEnd/myCheck/courseCheck"),
        meta: "课程审核",
      },
      {
        // 我的审核--课程审核--直播审核详情
        path: "/checkLiveDetail",
        name: "checkLiveDetail",
        component: () =>
          import("@/views/backStageEnd/myCheck/component/checkLiveDetail"),
        meta: "课程审核",
      },
      {
        // 我的审核--课程审核--点播审核详情
        path: "/checkdibbleDetail",
        name: "checkdibbleDetail",
        component: () =>
          import("@/views/backStageEnd/myCheck/component/checkdibbleDetail"),
        meta: "课程审核",
      },
      {
        // 考试审核
        path: "/testCheck",
        name: "testCheck",
        component: () => import("@/views/backStageEnd/myCheck/testCheck"),
      },
      {
        // 讲师审核
        path: "/lecturerCheck",
        name: "lecturerCheck",
        component: () => import("@/views/backStageEnd/myCheck/lecturerCheck"),
      },
      {
        // 系统管理--字典管理
        path: "/dictionaryManagement",
        name: "dictionaryManagement",
        component: () =>
          import("@/views/backStageEnd/systemManagement/dictionaryManagement"),
        meta: "字典管理",
      },
      {
        // 系统管理--字典管理--字典详情
        path: "/dictionaryDetail",
        name: "dictionaryDetail",
        component: () =>
          import("@/views/backStageEnd/systemManagement/dictionaryDetail"),
        meta: "字典管理",
      },
      {
        // 系统管理--角色管理
        path: "/roleManagement",
        name: "roleManagement",
        component: () =>
          import("@/views/backStageEnd/systemManagement/roleManagement"),
        meta: "角色管理",
      },
      {
        // 系统管理--用户管理
        path: "/userManage",
        name: "userManage",
        component: () =>
          import("@/views/backStageEnd/systemManagement/userManage/index"),
        meta: "用户管理",
      },
      {
        //系统管理--菜单管理
        path: "/menuManagement",
        name: "menuManagement",
        component: () =>
          import("@/views/backStageEnd/systemManagement/menuManagement"),
        meta: "菜单管理",
      },
      {
        //轮播图管理
        path: "/carouselManagement",
        name: "carouselManagement",
        component: () =>
          import("@/views/backStageEnd/systemManagement/carouselManagement"),
        meta: "轮播图管理",
      },
      {
        //组织菜单
        path: "/organizaManagement",
        name: "organizaManagement",
        component: () =>
          import("@/views/backStageEnd/systemManagement/organizaManagement"),
      },
      {
        //医疗科目
        path: "/medDepartManagement",
        name: "medDepartManagement",
        component: () =>
          import("@/views/backStageEnd/systemManagement/medDepartManagement"),
      },
      {
        //咨询管理
        path: "/consultManagement",
        name: "consultManagement",
        component: () =>
          import("@/views/backStageEnd/consultManagement/consultManagement"),
      },
      {
        //新建咨询
        path: "/addConsult",
        name: "addConsult",
        component: () =>
          import("@/views/backStageEnd/consultManagement/addConsult"),
      },
      {
        // 咨询审核
        path: "/consultCheck",
        name: "consultCheck",
        component: () => import("@/views/backStageEnd/myCheck/consultCheck"),
      },
      {
        // 咨询预览
        path: "/previewConsult",
        name: "previewConsult",
        component: () =>
          import("@/views/backStageEnd/consultManagement/previewConsult"),
      },
      {
        //数据统计
        path: '/myDataStatistics',
        name: 'myDataStatistics',
        component: () =>
          import("@/views/backStageEnd/myDataStatistics/myDataStatistics"),
      }
    ],
  },
  // 融合跳转页面
  {
    path: "/index",
    name: "index",
    components: {
      fusion: () => import("@/views/index"),
    },
    children: [],
  },
  // //个人中心
  // {
  //   path: "/personalCenter",
  //   name: "personalCenter",
  //   component: () => import("@/views/studentsEnd/personalCenter"), //children: []

  // }
];
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const router = new VueRouter({
  routes,
});
export default router;
