const router = [
  {
    path: '/exam',
    name: 'exam',
    component: () => import(/*webpackChunkName: 'exam'*/'@/views/studentsEnd/personalCenter/exam/index'),
  },
  {
    path: '/answer',
    name: 'answer',
    component: () => import(/*webpackChunkName: 'exam'*/'@/views/studentsEnd/personalCenter/exam/answer'),
  },
  {
    path: 'exam/result/:id',
    component: () => import(/*webpackChunkName: 'exam'*/'@/views/studentsEnd/personalCenter/exam/result'),
    name: 'ShowExam'
  },
]

export default router