import axios from 'axios';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import MD5 from 'js-md5';
import TIM from 'tim-js-sdk';
import Vue from 'vue';
import App from './App.vue';
// import './assets/css/common.scss';
import './assets/css/global.css';
import loadEvents from './assets/js/loading';
import './components/backStageComponent/YForm/index';
import router from './router';
import store from './store';
import tim from './tengxun/IM';

// import './styles/common.scss';

Vue.use(ElementUI);
Vue.prototype.$axios = axios;
Vue.prototype.$EventBus = new Vue();
Vue.prototype.loading = loadEvents;
Vue.prototype.$tim = tim;
Vue.prototype.$TIM = TIM;
Vue.prototype.$md5 = MD5

Vue.config.productionTip = false;
router.beforeEach((to, from, next) => {
  let user = sessionStorage.getItem('educationToken') || localStorage.getItem('educationToken')
  if (to.path === '/index') {
    next()
  } else if(to.matched[0].path === '/admin' && user == null){
    next('/home')
  }else {
    next()
  }
})
new Vue({
  router,
  store: store,
  render: h => h(App),
}).$mount('#app');
