const router = [
  {
    path: '/questionStoreList',
    name: 'questionStoreList',
    component: () => import('@/views/backStageEnd/examManagement/questionStoreList'),
  },
  {
    path: '/questionStoreType',
    name: 'questionStoreType',
    component: () => import('@/views/backStageEnd/examManagement/questionStoreType'),
  },
  {
    path: '/questionList',
    name: 'questionList',
    component: () => import('@/views/backStageEnd/examManagement/questionList'),
  },
  {
    path: '/examList',
    name: 'examList',
    component: () => import('@/views/backStageEnd/examManagement/examList'),
  },
  {
    path: '/questionaire',
    name: 'questionaire',
    component: () => import('@/views/backStageEnd/examManagement/questionnaire'),
  },
  {
    path: '/questionaire/statistic',
    name: 'questionaireStatistic',
    component: () => import('@/views/backStageEnd/examManagement/questionaireStatistic'),
  },
]

export default router
