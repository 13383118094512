const router = [
  {//我的课程
    path: "/myCourses",
    name: "myCourses",
    component: () => import("@/views/backStageEnd/myCourses/myCourses"),
  },
  {// 页面菜单
    path: "/pageMenu",
    name: "pageMenu",
    component: () => import("@/views/backStageEnd/myCourses/pageMenu"),
  },
  {// 观看控制
    path: "/watchTheControl",
    name: "watchTheControl",
    component: () => import("@/views/backStageEnd/myCourses/watchTheControl"),
  },
  {// 数据统计
    path: "/dataStatistics",
    name: "dataStatistics",
    component: () => import("@/views/backStageEnd/myCourses/dataStatistics"),
  },
]
export default router
