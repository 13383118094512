import axios from '@/plugins/http.js';
class personalCenterAPI {
  getLiveList(courseType,page,pageSize,state,subjectId,keyword) {//获取直播列表
    return axios.get(`/client/course-list?courseType=${courseType}
    &page=${page}
    &pageSize=${pageSize}
    &state=${state}
    &subjectId=${subjectId}
    &keyword=${keyword}`)
  }
  getProfile() {//获取个人信息
    return axios.get(`/profile`)
  }
  getLive(page,pageSize,courseType,userId,keyword) {//获取收藏直播列表
    return axios.get(`/user/${userId}/collect/course-list?page=${page}
    &pageSize=${pageSize}
    &courseType=${courseType}
    &keyword=${keyword}`)
  }
  getCollect(userId,params) {//收藏
    return axios.post(`/user/${userId}/collect`,params)
  }
  deleteCollect(userId,params) {//取消收藏
    return axios.delete(`/user/${userId}/collect`,params)
  }
  getEvaluationList(params) {//获取评价列表
    return axios.get(`/evaluation-list?${params}`)
  }
  changePhone(params) {//个人中心更换手机号
    return axios.put(`/profile/phone`,params)
  }
  getVerifyCode(mobile,type,oldVerifyCode) {//获取旧验证码
    return axios.get(`/verify-code?mobile=${mobile}&type=${type}&oldVerifyCode=${oldVerifyCode}`)
  }
  changePassword(params) {//个人中心更换密码
    return axios.put(`/profile/password`,params)
  }
  putProfile(params) {//修改个人信息
    return axios.put(`/profile`,params)
  }
}
export default new personalCenterAPI()
