import Vue from "vue";
import Element from "element-ui";
import cloneDeep from "lodash/cloneDeep";
import "./index.css";
import {
  YForm,
  YField,
  YButton,
  YQueryTable,
  YTable,
  getType,
} from "./install";

const { useYFormLog } = require("./index.umd.min.js");

Element.Card.props.shadow.default = "never";
Element.Button.props.type.default = "primary";
Element.Dialog.props.closeOnClickModal.default = false;
Element.Link.props.underline.default = false;
Element.Link.props.type.default = "primary";
Element.Drawer.props.wrapperClosable.default = false;
useYFormLog(process.env.NODE_ENV === "development");

export const PLACEHOLDER = new Map([
  [
    {
      component: ["el-input", "el-input-number"],
    },
    {
      placeholder: "请输入",
    },
  ],
  [
    {
      component: ["el-date-picker"],
      "$attrs.type": ["daterange", "datetimerange"],
    },
    {
      "start-placeholder": "开始日期",
      "end-placeholder": "结束日期",
    },
  ],
  [
    {
      component: ["el-select", "el-cascader"],
    },
    {
      filterable: true,
      placeholder: "请选择",
    },
  ],
  [
    {
      component: ["el-date-picker"],
      "$attrs.afterToday": true,
    },
  ],
]);

Vue.use(YForm, {
  name: "YForm",
});
Vue.use(YField, {
  name: "YField",
  defaultComponent: "el-input",
  debug: process.env.NODE_ENV === "development",
  componentProps: function (data) {
    const { label = "" } = data;
    const props = {
      placeholder: `请输入${label}`,
      clearable: true,
      size: "medium",
    };
    const rules = Array.from(PLACEHOLDER);
    const index = rules.findIndex((item) => {
      const keys = Object.keys(item[0]);

      return keys.every((val) => {
        const keyArr = val.split(".");
        let target = item[0];
        let current = data;

        target = target[val];

        if (keyArr.length > 1) {
          keyArr.forEach((ele) => {
            current = current[ele];
          });
        } else {
          if (val === "component") {
            current = current[val] || "el-input";
          } else {
            current = current[val];
          }
        }

        if (getType(target) === "array") {
          return target.includes(current);
        } else if (["string", "boolean"].includes(getType(target))) {
          return target === current;
        }
      });
    });

    if (~index) {
      const target = cloneDeep(rules[index][1]);

      if (target.placeholder) {
        target.placeholder += label;
      }
      Object.assign(props, target);
    }

    return props;
  },
});
Vue.use(YButton);
Vue.use(YQueryTable, {
  name: "YQueryTable",
  pagination: {
    background: true,
    pageSize: 10,
    // pageSizes: [10, 15, 20, 25, 30],
    // layout: 'total, sizes, prev, pager, next, jumper'
  },
});
Vue.use(YTable);
