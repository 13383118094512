import TIM from 'tim-js-sdk'
import COS from 'cos-js-sdk-v5'
let options = {
  // SDKAppID: 1400310919 // 接入时需要将0替换为您的即时通信应用的 SDKAppID
  SDKAppID:1400325542
};
let tim = TIM.create(options); // SDK 实例通常用 tim 表示
// 设置 SDK 日志输出级别，详细分级请参见 setLogLevel 接口的说明
tim.setLogLevel(0); // 普通级别，日志量较多，接入时建议使用
// tim.setLogLevel(1); // release级别，SDK 输出关键信息，生产环境时建议使用
// 将腾讯云对象存储服务 SDK （以下简称 COS SDK）注册为插件，IM SDK 发送文件、图片等消息时，需要用到腾讯云的 COS 服务
// HTML5 环境，注册 COS SDK
tim.registerPlugin({'cos-js-sdk': COS});
export default tim;
