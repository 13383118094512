import http from '@/plugins/http.js';

class Common {
  login(params) {//登录
    return http.get(`/token?${params}`);
  }
  getVerifyCode(params) {//获取验证码
    return http.get(`/verify-code?${params}`);
  }
  getPassword(params) {//忘记密码/修改密码
    return http.put(`/password`,params);
  }
  getCosToken(){//对象存储获取临时秘钥
    return http.get(`/cos-token`)
  }
  getIMSign() {
    return http.get(`im-sign`)
  }
}

export default new Common()
